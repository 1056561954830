// import React, { Component, useCallback } from "react";
// import Particles from 'react-tsparticles';
// import { loadFull } from "tsparticles";

// const config = require('../JSON/particlesjs-config.json');


// export default class HomeBanner extends Component {
//       constructor() {
//         this.state = {
//             loading:true,
//             error:false,
//             particlesInit : useCallback(async engine => {
//               console.log(engine);
//               // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
//               // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
//               // starting from v2 you can add only the features you need reducing the bundle size
//               await loadFull(engine);
//           }, []),
//           particlesLoaded : useCallback(async container => {
//               console.log(container);
//           }, [])
//         }

//         }
      


//   render() {
//     return (
//       <div
//         className={
//           props.currentPage === "/empower" ||
//           props.currentPage === "/enable"
//             ? "bannerContainer eBanner"
//             : "bannerContainer"
//         }
//       >
//         {props.currentPage === "/" ? (
//           // <Particles className="particlesBanner" params={config}></Particles>
//           <Particles id="tsparticles" url="http://foo.bar/particles.json" init={this.state.particlesInit} loaded={this.state.particlesLoaded} />
//         ) : null}

//         {props.currentPage === "/" ? (
//           <div className="banner">
//             <h1>We Enable</h1>
//             <div>
//               <h2 className="bannerText">
//                 you to build and design your digital application in a diverse
//                 and equitable environment
//               </h2>
//             </div>
//           </div>
//         ) : null}
//         {props.currentPage === "/enable" ? (
//           <div className="enableBannerContent">
//             <h1>We Enable</h1>
//             <h2 className="bannerText">
//               LIFE3 creates environments where team of thinkers and learners are
//               enabled. Our initiative is having a positive impact on the number
//               of underrepresented groups learning about , securing jobs, and
//               approaching entrepreneurial ventures.
//             </h2>
//           </div>
//         ) : null}
//         {props.currentPage === "/empower" ? (
//           <div className="banner">
//             <h1> We Empower </h1>
//             <h2 className="bannerText">
//               inclusion of underrepresented communities in
//               technology-focused careers and entrepreneurial ventures
//             </h2>

//           </div>
//         ) : null}
//       </div>
//     );
//   }
// }
import React, { Component, useCallback } from "react";
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";

const config = require('../JSON/particlesjs-config.json');


const HomeBanner = (props) => {

    const particlesInit = useCallback(async engine => {
      console.log(engine);
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
    })
      
      const particlesLoaded = useCallback(async container => {
        console.log(container);
      }, [])

  return (
    <div
      className={
        props.currentPage === "/empower" ||
        props.currentPage === "/enable"
          ? "bannerContainer eBanner"
          : "bannerContainer"
      }
    >
      {props.currentPage === "/" ? (
        // <Particles className="particlesBanner" params={config}></Particles>
        <Particles id="tsparticles"options={{
          background: {
              color: {
                  value: "#010112",
              },
          },
          fpsLimit: 120,
          interactivity: {
              events: {
                  onClick: {
                      enable: true,
                      mode: "push",
                  },
                  onHover: {
                      enable: true,
                      mode: "repulse",
                  },
                  resize: true,
              },
              modes: {
                  push: {
                      quantity: 4,
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4,
                  },
              },
          },
          particles: {
              color: {
                  value: "#6aa491",
              },
              links: {
                  color: "#787777",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
              },
              collisions: {
                  enable: true,
              },
              move: {
                  directions: "none",
                  enable: true,
                  outModes: {
                      default: "bounce",
                  },
                  random: false,
                  speed: 2,
                  straight: false,
              },
              number: {
                  density: {
                      enable: true,
                      area: 800,
                  },
                  value: 40,
              },
              opacity: {
                  value: 0.3,
              },
              shape: {
                  type: "circle",
              },
              size: {
                  value:  10 ,
              },
          },
          detectRetina: true,
      }} init={particlesInit} loaded={particlesLoaded} />
      ) : null}

      {props.currentPage === "/" ? (
        <div className="banner">
          <h1>We Enable</h1>
          <h1 style={{color: 'white', fontSize: '3.5rem',  'text-transform': 'lowercase', fontWeight: 500, marginTop: '2rem', fontFamily: '"Esteban",serif'}}>
            you to build and design your digital application in a diverse
            and equitable environment
          </h1>
          <div>
          </div>
        </div>
      ) : null}
      {props.currentPage === "/enable" ? (
        <div className="enableBannerContent">
          <h1>We Enable</h1>
          <h2 className="bannerText">
            LIFE3 creates environments where team of thinkers and learners are
            enabled. Our initiative is having a positive impact on the number
            of underrepresented groups learning about , securing jobs, and
            approaching entrepreneurial ventures.
          </h2>
        </div>
      ) : null}
      {props.currentPage === "/empower" ? (
        <div className="banner">
          <h1> We Empower </h1>
          <h2 className="bannerText">
            inclusion of underrepresented communities in
            technology-focused careers and entrepreneurial ventures
          </h2>

        </div>
      ) : null}
    </div>
    // return (
    // );
  )
}

export default HomeBanner
